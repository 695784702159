export function isLocalhost(hostname: string) {
  if (hostname == "localhost") {
    return true;
  }

  return false;
}

export function isDevelopment(hostname: string) {
  return hostname === "dev-admin.keeb.supply";
}

export function determineApi(location: Location) {
  const isLocal = isLocalhost(location.hostname);
  const isDev = isDevelopment(location.hostname);

  // TODO: should eventually be agnostic & moved to backend
  return isLocal
    ? `${location.origin}`
    : isDev
      ? `https://dev.nf-eu-west.keeb.supply`
      : `https://prod.nf-eu-west.keeb.supply`;
}

export function extractToken(window: Window) {
  let token = window.localStorage.getItem("vnd__authToken");

  if (token) {
    token = token.replace(/\"/g, "");
  }

  return token;
}
