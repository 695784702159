export const extensionRoutes = [  {
    path: 'extensions/invoices',
    loadChildren: () => import('./extensions/2ea67e15d953a46c1c3b0fcad18c246cd73d0a8e58e58994349cec43de556482/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: 'extensions/orders-extended',
    loadChildren: () => import('./extensions/fa7c6b8d69f54965913c2e5eb76c937dcc88b29347a87dda94b05772245039f1/routes'),
  },
  {
    path: 'extensions/tax-export',
    loadChildren: () => import('./extensions/5b355a46194ddcb51bf1b3d934529adef519d9ea6dc8cc16719f77866a0aa71f/routes'),
  }];
