import {getCountryISO3} from "ts-country-iso-2-to-3";

export function getISO3CountryCode(countryCode?: string) {
  if (!countryCode) {
    throw new Error("No country code given!");
  }

  if (countryCode.length === 3) {
    return countryCode;
  }

  return getCountryISO3(countryCode);
}

export function countryCodeIsDEU(countryCode?: string) {
  return countryCode === "DE";
}

export const euCountries = [
  "BEL",
  "BGR",
  "CZE",
  "DNK",
  "DEU",
  "EST",
  "IRL",
  "GRC",
  "ESP",
  "FRA",
  "HRV",
  "ITA",
  "CYP",
  "LVA",
  "LTU",
  "LUX",
  "HUN",
  "MLT",
  "NLD",
  "AUT",
  "POL",
  "PRT",
  "ROU",
  "SVN",
  "SVK",
  "FIN",
  "SWE",
];

export function countryCodeIsEU(countryCode?: string) {
  return euCountries.includes(getISO3CountryCode(countryCode));
}
