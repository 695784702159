import {firstValueFrom, BehaviorSubject} from "rxjs";
import {Order} from "@vendure/core";
import {ActionBarContext} from "@vendure/admin-ui/core";

export const orderSubject = new BehaviorSubject<null | Order>(null);

export async function getOrder(context: ActionBarContext): Promise<Order> {
    if(orderSubject.getValue()) {
        return orderSubject.getValue() as Order;
    }

    console.warn("DHLDP UI Extensions: Subject for current order is unset");
    return firstValueFrom(context.route.data).then(data => data.detail.result.order)
}

export function orderHasPendingFulfillments(order: Order) {
    const pendingFulfillments = getPendingFulfillments(order);
    return pendingFulfillments.length > 0
}

export function getPendingFulfillments(order: Order) {
    return order.fulfillments.filter(fulfillment => fulfillment.state === "Pending");
}

export function expectSingleFulfillment(order: Order) {
    const pendingFulfillments = getPendingFulfillments(order);

    if (pendingFulfillments.length !== 1) {
        throw new Error(`Cannot print customs doc!\nIllegal number of pending fulfillments: ${pendingFulfillments.length}\nMake sure a fulfillment exists and that only one fulfillment is in the 'Pending' state.`)
    }

    return pendingFulfillments[0];
}