import { NgModule } from '@angular/core';
import { SharedModule, addActionBarItem, ActionBarContext } from '@vendure/admin-ui/core';

const isLocalhost = (hostname: string) => {
    if (hostname == "localhost") {
        return true;
    }

    return false;
}

function isDevelopement(hostname: string) {
    return hostname === 'dev-admin.keeb.supply'
}

const callRoute = (view: Window, relativeRoute: string) => {
    let token = view.window.localStorage.getItem("vnd__authToken");
    if (!token) {
        return;
    }
    token = token.replace(/\"/g, "");

    const isLocal = isLocalhost(view.window.location.hostname);
    const isDev = isDevelopement(view.window.location.hostname);

    // TODO: should eventually be agnostic & moved to backend
    const apiRoute = isLocal ? `${view.window.location.origin}${relativeRoute}` :
        (isDev ? `https://dev.nf-eu-west.keeb.supply${relativeRoute}` : `https://prod.nf-eu-west.keeb.supply${relativeRoute}`);
    const viewRoutePrefix = isLocal ? view.window.location.origin : (isDev ? "https://store.keeb.supply" : "https://keeb.supply");

    fetch(apiRoute, {
        method: "GET",
        headers: new Headers(
            {
                Authorization: `Bearer ${token}`
            }
        )
    }).then((resp) => {
        resp.text().then(text => {

            if (resp.status != 200) {
                alert(`An error occured: ${text}`);
                return;
            }

            const newView = view.window.open(viewRoutePrefix + text);
            if (newView) {
                newView.focus();
            }
        });
    }).catch((error) => {
        alert(`An error occured: ${error}`);
    });
}

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        addActionBarItem({
            id: "order-invoice-recreate-button",
            label: "Recreate Invoice",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: ActionBarContext) => {

                const view = event.view;
                const id = context.route.snapshot.params.id;

                if (!id || !view) {
                    return;
                }

                callRoute(view, `/invoice-extender/recreate/${id}`);

            },
            requiresPermission: "SuperAdmin"
        }),
        addActionBarItem({
            id: "order-invoice-print-button",
            label: "Print Invoice",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: ActionBarContext) => {

                const view = event.view;
                const id = context.route.snapshot.params.id;

                if (!id || !view) {
                    return;
                }

                callRoute(view, `/invoice-extender/download/${id}`);

            },
            requiresPermission: "SuperAdmin"
        })
    ]
})

// @ts-ignore
export class InvoiceButtonsModule { }