import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem({
            id: 'tax-export',
            label: 'Tax export',
            routerLink: ['/extensions/tax-export'],
            icon: 'euro',
        },
        'sales'),
];