import {
  addActionBarItem,
  ActionBarContext,
} from "@vendure/admin-ui/core";
import {expectSingleFulfillment, getOrder, orderHasPendingFulfillments, orderSubject} from "./order.util";
import {map, switchMap} from "rxjs/operators";
import {Order} from "@vendure/core";

export default [
    addActionBarItem({
      id: "label-button",
      label: "Print label",
      locationId: "order-detail",
      buttonStyle: "solid",
        buttonColor: "success",
      onClick: async (event: MouseEvent, context: ActionBarContext) => {
        const order = await getOrder(context);
        const fulfillment = expectSingleFulfillment(order);

        if(event.view && "labelUrl" in fulfillment.customFields) {
          event.view.window.open(fulfillment.customFields.labelUrl as string);
        }
      },
      buttonState: context => {
        return context.route.data.pipe(
            switchMap(data => data.detail.entity),
            map((_order: any) => {
              const order = _order as Order;
              orderSubject.next(order);

              const hasPendingFulfillments = orderHasPendingFulfillments(order);
              return {
                disabled: !hasPendingFulfillments,
                visible: true,
              };
            }),
        );
      },
      requiresPermission: "ReadOrder",
    }),
  ]