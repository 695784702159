import { addNavMenuSection } from "@vendure/admin-ui/core";

export default [
  addNavMenuSection(
    {
      id: "sales",
      label: "Sales",
      items: [
        {
          id: "sales",
          label: "Orders Extended",
          routerLink: ["/extensions/orders-extended"],
          icon: "shopping-cart",
        },
      ],
    },
    "sales",
  ),
];
