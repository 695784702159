import {
  addActionBarItem,
  ActionBarContext,
} from "@vendure/admin-ui/core";
import { determineApi, extractToken } from "./ui.util";

export default [
    addActionBarItem({
      id: "manifest-button",
      label: "Manifest shipments",
      locationId: "order-list",
      buttonStyle: "solid",
      onClick: (event: MouseEvent, context: ActionBarContext) => {
        const view = event.view;
        if (view) {
          const apiRoute = `${determineApi(view.window.location)}/manifest`;

          let token = extractToken(view.window);
          if (token) {
            fetch(apiRoute, {
              method: "POST",
              headers: new Headers({
                Authorization: `Bearer ${token}`,
              }),
            })
              .then((resp) => {
                resp.text().then((text) => {
                  alert(text);
                });
              })
              .catch((error) => {
                alert(`An error occured: ${error}`);
              });
          }
        }
      },
      requiresPermission: "ReadOrder",
    })]
