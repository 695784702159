import {
    addActionBarItem,
    ActionBarContext,
} from "@vendure/admin-ui/core";
import {map, switchMap} from "rxjs/operators";
import {countryCodeIsEU} from "./shared/country-code.util";
import {Order} from "@vendure/core";
import {expectSingleFulfillment, getOrder, orderHasPendingFulfillments} from "./order.util";

export default [
        addActionBarItem({
            id: "customs-button",
            label: "Print customs",
            locationId: "order-detail",
            buttonStyle: "solid",
            buttonColor: "warning",
            onClick: async (event: MouseEvent, context: ActionBarContext) => {
                const order = await getOrder(context);
                const fulfillment = expectSingleFulfillment(order);

                if(event.view && "customsDocUrl" in fulfillment.customFields) {
                    event.view.window.open(fulfillment.customFields.customsDocUrl as string);
                }
            },
            buttonState: context => {
                return context.route.data.pipe(
                    // For any of the detail pages, we can get an observable stream
                    // of the entity with the following "switchMap" function:
                    switchMap(data => data.detail.entity),
                    map((_order: any) => {
                        const order = _order as Order;

                        const isEU = countryCodeIsEU(order.shippingAddress.countryCode);
                        const isWarenpost = order.shippingLines.every(line => line.shippingMethod.code.toLowerCase().includes("warenpost"))
                        const hasPendingFulfillments = orderHasPendingFulfillments(order);

                        return {
                            disabled: !hasPendingFulfillments || isEU || isWarenpost,
                            visible: !isEU && !isWarenpost,
                        };
                    }),
                );
            },
            requiresPermission: "ReadOrder",
        }),
    ]
