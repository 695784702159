import {
  addActionBarItem,
  ActionBarContext,
} from "@vendure/admin-ui/core";
import {getOrder, getPendingFulfillments, orderHasPendingFulfillments} from "./order.util";
import {map, switchMap} from "rxjs/operators";
import {Order} from "@vendure/core";
import {lastValueFrom} from "rxjs";

export default [
    addActionBarItem({
      id: "mark-shipped-button",
      label: "Mark as Shipped",
      locationId: "order-detail",
      buttonStyle: "solid",
        buttonColor: "primary",
      onClick: async (event: MouseEvent, context: ActionBarContext) => {
        const order = await getOrder(context);
        const fulfillments = getPendingFulfillments(order);

        await Promise.all(fulfillments.map(fulfillment =>
            lastValueFrom(context.dataService.order.transitionFulfillmentToState(fulfillment.id as string, "Shipped"))
        ));

        context.notificationService.success("Marked all pending fulfillments as shipped!")
      },
      buttonState: context => {
        return context.route.data.pipe(
            switchMap(data => data.detail.entity),
            map((_order: any) => {
              const order = _order as Order;
              const hasPendingFulfillments = orderHasPendingFulfillments(order);
              return {
                disabled: !hasPendingFulfillments,
                visible: true,
              };
            }),
        );
      },
      requiresPermission: "ReadOrder",
    }),
  ]